import { navigate } from "gatsby";
import React, { useState } from "react";

export const Form = () => {
  const [values, setValues] = useState({
    category: "",
    nameKanji: "",
    nameKana: "",
    email: "",
    tel: "",
    content: "",
  });

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/form", {
        method: "POST",
        body: JSON.stringify({ values }),
      });
      console.log("response.status", response.status);
      if (response.status === 200) {
        console.log("entered");
        navigate("/contact/success");
      } else {
        setError("入力欄が正しくありません");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  return (
    <section className="bg-white px-6 py-8 md:px-8 lg:px-0">
      <div className="max-w-xl mx-auto">
        <div className="mb-8">
          <h3 className="text-center text-2xl font-bold text-primary-gold tracking-widest mb-1 md:text-3xl md:mb-2">
            CONTACT FORM
          </h3>
          <h1 className="text-center block text-sm tracking-widest">
            お問い合わせ
          </h1>
        </div>
        <div className="mb-8">
          <a
            href="https://lin.ee/Vw3HGc9"
            target="_blank"
            rel="noreferrer"
            className="w-fit-content mx-auto text-center block text-sm tracking-wider underline md:text-base"
          >
            ※美容皮膚科のご予約はこちら
          </a>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mb-8 flex flex-col gap-x-8 font-sans"
        >
          <ul className="flex flex-col gap-y-8">
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="category"
              >
                <span className="font-medium">お問い合わせ種別</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <select
                className="block px-6 py-3 w-full font-sans bg-gray-100"
                name="category"
                id="category"
                onChange={(e) =>
                  setValues({ ...values, category: e.target.value })
                }
                required
                // style={`background-image: url(${data.selectArrow.publicURL});`}
              >
                <option disabled selected value="">
                  選択してください
                </option>
                <option value="クリニックへお問い合わせ">
                  クリニックへお問い合わせ
                </option>
                <option value="採用についてのお問い合わせ">
                  採用についてのお問い合わせ
                </option>
                <option value="その他">その他</option>
              </select>
            </li>
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="nameKanji"
              >
                <span className="font-medium">お名前（漢字）</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <input
                className="block px-6 py-3 w-full bg-gray-100"
                type="text"
                name="nameKanji"
                id="nameKanji"
                placeholder="山田　花子"
                onChange={(e) =>
                  setValues({ ...values, nameKanji: e.target.value })
                }
                value={values.nameKanji}
                required
              />
            </li>
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="nameKana"
              >
                <span className="font-medium">お名前（フリガナ）</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <input
                className="block px-6 py-3 w-full bg-gray-100"
                type="text"
                name="nameKana"
                id="nameKana"
                placeholder="ヤマダ　ハナコ"
                value={values.nameKana}
                onChange={(e) =>
                  setValues({ ...values, nameKana: e.target.value })
                }
                required
              />
            </li>
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="email"
              >
                <span className="font-medium">メールアドレス</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <input
                className="block px-6 py-3 w-full bg-gray-100"
                type="email"
                name="email"
                id="email"
                placeholder="xxx@example.com"
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                required
              />
            </li>
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="tel"
              >
                <span className="font-medium">電話番号</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <input
                className="block px-6 py-3 w-full bg-gray-100"
                type="tel"
                name="tel"
                id="tel"
                placeholder="03-1234-5678"
                value={values.tel}
                onChange={(e) => setValues({ ...values, tel: e.target.value })}
                required
              />
            </li>
            <li>
              <label
                className="flex items-center gap-x-4 tracking-wider mb-4"
                for="content"
              >
                <span className="font-medium">お問い合わせ内容</span>
                <span className="px-4 py-1 text-xs bg-primary-red text-white font-bold">
                  必須
                </span>
              </label>
              <textarea
                className="block px-6 py-3 w-full font-sans bg-gray-100"
                name="content"
                id="content"
                rows="8"
                maxlength="4000"
                placeholder="最大4000文字まで"
                value={values.content}
                onChange={(e) =>
                  setValues({ ...values, content: e.target.value })
                }
                required
              ></textarea>
            </li>
          </ul>
          <div className="mt-8 pb-12 md:pb-24">
            {error ? (
              <div className="px-3 py-3">
                <h4 className="text-red-500">{error}</h4>
              </div>
            ) : null}

            <div className="flex flex-col items-center justify-center mt-8 mx-auto w-56 md:w-64">
              <button
                type="submit"
                className="py-4 w-full text-white text-sm font-bold hover:bg-white bg-primary-gold hover:text-primary-gold border-2 border-primary-gold rounded-full transition duration-500 md:text-base md:font-normal"
              >
                送信する
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
