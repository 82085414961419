import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

export const MobileMenu = ({ open, setOpen }) => {
  const data = useStaticQuery(graphql`
    {
      flagFr: file(relativePath: { eq: "flag-fr.svg" }) {
        publicURL
      }
      flagEn: file(relativePath: { eq: "flag-en.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <nav
      className={`${
        open ? "" : "max-h-0 opacity-0 pointer-events-none"
      } fixed h-screen inset-0 bg-primary-pink px-8 z-40 transition
    duration-500 py-16`}
    >
      <ul className="flex flex-col gap-y-4 py-16">
        <li className="text-white font-bold tracking-wider">
          <Link to="/">HOME</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/about">クリニック紹介</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/treatment">施術内容</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/treatment#price">料金表</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/online">オンライン診療</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/contact">お問合わせ</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
        <li className="text-white font-bold tracking-wider">
          <Link to="/news">お知らせ・ブログ</Link>
          <div className="w-full h-px bg-secondary-gold mt-4" />
        </li>
      </ul>
    </nav>
  );
};
