import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Hifu = ({ showHifu, setShowHifu }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowHifu(false)
    }
  }
  return (
    <>
      {showHifu ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowHifu(!showHifu)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/hifu-modal.jpeg"
                alt="HIFU（ウルセラ）　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  HIFU（ウルセラ）とは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ウルセラは、リフトアップ治療機の中で唯一、皮膚の一番深い層のSMAS層まで強力に引き上げることができる新しいマシンです。
                  <br />
                  ウルセラはFDAで認可されている画期的なたるみの治療方法です。
                  <br />
                  <br />
                  高密度の超音波をあてることで、SMAS層（表在性筋膜）を引き上げてたるみを解消します。
                  <br />
                  SMAS層（表在性筋膜）を引き上げる治療としまして外科的手術のフェイスリフトがありますが、切開までは考えていないという方も多くいます。
                  <br />
                  そんな方々に、ウルセラはとても人気な治療方法になります。
                  <br />
                  <br />
                  また、別名「切らないフェイスリフト」とも言われています。
                  <br />
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  HIFU（ウルセラ）はこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・顔は痩せているが、たるみが気になる
                  <br />
                  ・顔の下半分のたるみが気になる
                  <br />
                  ・フェイスラインがたるんで見える
                  <br />
                  ・小皺の改善・肌のハリが欲しい
                  <br />
                  ・顔を持ち上げると若く見える
                  <br />
                  ・切らずに、たるみをどうにかしたい
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  たるみの根本原因となっている肌の深い層SMAS筋層から引き上げるため、高い効果と持続時間を感じていただけます。
                  <br />
                  分かりやすくいうとポニーテールをした時のように、顔を引き上げます。
                  <br />
                  照射範囲は額から首まで可能であり、その場合には頬領域だけではなく、眉の引き上げによって目を開けるのが楽になったり、首（特に正面）の引き締めによって弛んだあご下のラインを改善します。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ウルセラシステムは顔の頬部を主に支えている筋膜（SMAS），簡単に言うと顔を吊り上げている支えの部分に作用する唯一の治療器です。
                  <br />
                  そもそもフェイスリフト手術、つまり耳の前を切って皮膚を含めて顔を引き上げるスタンダードなたるみ取り手術の主目的は、皮膚を切り取るのみならず、SMAS筋膜を縫い縮めることです。
                  <br />
                  しかしこのような方法では長期間の腫れや傷が落ち着くまでに要する時間など，ダウンタイムと云われる期間があります。
                  <br />
                  このような手術をすることなく，仕事を休むことなく、たるみ取り効果を得ようとするのがウルセラシステムなのです。
                </p>
                <span className="block mt-2 text-red-500 underline font-bold text-sm md:text-base md:mt-4">
                  米国FDA（米国食品医薬品局）で初めて「リフトアップ」の効果がある医療機器として認められた、唯一の医療機器です。
                </span>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・赤・熱感・痒み・痛み・乾燥が生じます。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・稀に、コメド、毛嚢炎、埋没毛、膿疱、ほくろ、しみの部分が薄くなる・消える・一時的に濃くなる、色素沈着や内出血、灼熱感、瘢痕が生じることがあります。
                  </span>
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  症例紹介
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ウルセラはHIFUという超音波を使って、皮下組織の筋膜へピンポイントに熱を与え、コラーゲンを再生することでたるみを改善する治療です。
                  <br />
                  リフトアップ効果は約半年から１年間持続します。効果の現れ方には個人差がございます。
                </p>
                <div className="lg:flex flex-wrap justify-between gap-y-4 lg:w-full mx-auto md:w-4/5">
                  <StaticImage
                    className="mt-6 lg:w-5/12"
                    src="../../content/assets/hifu-beforeafter01.jpeg"
                    alt="HIFU（ウルセラ）　ビフォーアフター画像 01"
                    placeholder="blurred"
                    quality="100"
                  />
                  <StaticImage
                    className="mt-6 lg:w-5/12"
                    src="../../content/assets/hifu-beforeafter02.jpeg"
                    alt="HIFU（ウルセラ）　ビフォーアフター画像 02"
                    placeholder="blurred"
                    quality="100"
                  />
                  <StaticImage
                    className="mt-6 lg:w-5/12"
                    src="../../content/assets/hifu-beforeafter03.jpeg"
                    alt="HIFU（ウルセラ）　ビフォーアフター画像 03"
                    placeholder="blurred"
                    quality="100"
                  />
                  <span className="text-xs md:text-sm text-secondary-gray mt-auto">
                    ※写真提供：Dr. Kyle Seo
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
