import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Online = () => {
  const data = useStaticQuery(graphql`
    {
      curonIcon: file(relativePath: { eq: "curon-app-icon.svg" }) {
        publicURL
      }
      iphoneQr: file(relativePath: { eq: "iphoneqr.png" }) {
        publicURL
      }
      androidQr: file(relativePath: { eq: "androidqr.png" }) {
        publicURL
      }
      appstore: file(relativePath: { eq: "appstore.png" }) {
        publicURL
      }
      googlePlay: file(relativePath: { eq: "google-play-badge.png" }) {
        publicURL
      }
      iphone1: file(relativePath: { eq: "iphone-1.png" }) {
        publicURL
      }
      iphone2: file(relativePath: { eq: "iphone-2.png" }) {
        publicURL
      }
      iphone3: file(relativePath: { eq: "iphone-3.png" }) {
        publicURL
      }
      iphone4: file(relativePath: { eq: "iphone-4.png" }) {
        publicURL
      }
      iphone5: file(relativePath: { eq: "iphone-5.png" }) {
        publicURL
      }
      iphone6: file(relativePath: { eq: "iphone-6.png" }) {
        publicURL
      }
      iphone7: file(relativePath: { eq: "iphone-7.png" }) {
        publicURL
      }
      iphone8: file(relativePath: { eq: "iphone-8.png" }) {
        publicURL
      }
      iphone9: file(relativePath: { eq: "iphone-9.png" }) {
        publicURL
      }
      iphone10: file(relativePath: { eq: "iphone-10.png" }) {
        publicURL
      }
      iphone11: file(relativePath: { eq: "iphone-11.png" }) {
        publicURL
      }
      iphone12: file(relativePath: { eq: "iphone-12.png" }) {
        publicURL
      }
      iphone13: file(relativePath: { eq: "iphone-13.png" }) {
        publicURL
      }
      iphone14: file(relativePath: { eq: "iphone-14.png" }) {
        publicURL
      }
    }
  `)
  return (
    <section className="px-6 py-8 bg-white md:px-8 lg:px-0">
      <div className="mx-auto max-w-4xl">
        <div className="mb-8">
          <h3 className="mb-1 text-center text-primary-gold text-2xl font-bold tracking-widest md:mb-2 md:text-3xl">
            ONLINE
          </h3>
          <h1 className="block text-center text-sm tracking-widest">
            オンライン診療
          </h1>
        </div>
        <div className="mb-8">
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">オンライン診療のメリット</h5>
            </div>
            <div className="flex items-center py-4">
              <span className="text-sm md:text-base">
                オンライン診療とは、予約から受診、支払いまでをインターネットを通して行うことができる新しい通院のかたちです。完全予約制で外出することなくご自宅や会社等のご都合の良い場所で受診して頂けますので是非ご活用ください。
                <br />
                <br />
                オンライン診療システム（curon）利用料金：330円（税込み）
                <br />
                <br />
                診察・相談料：1,500円（税込み）
                <br />
                ※「オンライン診療メニュー」のいずれかをご購入のお客様は診察・相談料が無料となります。
              </span>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                オンライン診療メニュー（所要時間は約3分）
              </h5>
            </div>
            <div className="flex items-center py-4">
              <span className="text-sm md:text-base">
                【カウンセリング】 ZO SKIN(ゼオスキン）
                <br />
                <br />
                医療機関専売品のZO SKIN(ゼオスキン）のカウンセリングです。
                <br />
                お悩みにあわせた商品をご案内いたします。
                <br />
                カウンセリング後、 医師の診察となります。
                <br />
                診察後に商品をご希望の場合、別途商品代＋郵送料が必要となります。
                <br />
                <br />
                <br />
                【カウンセリング】ダイエット注射 (GLP-1)
                <br />
                お悩みにあわせた商品をご案内いたします。
                <br />
                カウンセリング後、医師の診察となります。
                <br />
                診察後に商品をご希望の場合、別途商品代＋郵送料が必要となります。
                <br />
                <br />
                <br />
                【カウンセリング】医療機関専売コスメ
                <br />
                <br />
                当院では、
                MDEAR（エムディア）やWiQo（ワイコ）といった、医療機関専売コスメの取り扱いもございます。
                <br />
                費用は各種コスメ代＋郵送料となります。
              </span>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP 1】オンライン診療アプリcuron（クロン）をダウンロード
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.curonIcon.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphoneQr.publicURL} alt="" />
                  <a
                    href="https://apps.apple.com/jp/app/id1101684249"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={data.appstore.publicURL} alt="" />
                  </a>
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.androidQr.publicURL} alt="" />
                  <a
                    href="https://play.google.com/store/apps/details?id=curon.micin.jp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={data.googlePlay.publicURL} alt="" />
                  </a>
                </div>
              </div>
              <div className="mt-12 mx-auto w-fit-content">
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.515137 1.46501L9.00014 9.95001L17.4851 1.46501L16.0711 0.0500126L9.00014 7.12201L1.92914 0.0500126L0.515137 1.46501Z"
                    fill="#BF953F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP 2】ガイドに従い、アカウントを登録
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone1.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone2.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone3.publicURL} alt="" />
                </div>
              </div>
              <div className="mt-12 mx-auto w-fit-content">
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.515137 1.46501L9.00014 9.95001L17.4851 1.46501L16.0711 0.0500126L9.00014 7.12201L1.92914 0.0500126L0.515137 1.46501Z"
                    fill="#BF953F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP 3】「+医療機関を追加」 → クロン施設コード（40b9）を入力
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone4.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone5.publicURL} alt="" />
                </div>
              </div>
              <div className="mt-12 mx-auto w-fit-content">
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.515137 1.46501L9.00014 9.95001L17.4851 1.46501L16.0711 0.0500126L9.00014 7.12201L1.92914 0.0500126L0.515137 1.46501Z"
                    fill="#BF953F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP 4】個人情報を入力し、診察メニューを選択
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone6.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone7.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone8.publicURL} alt="" />
                </div>
              </div>
              <div className="mt-12 mx-auto w-fit-content">
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.515137 1.46501L9.00014 9.95001L17.4851 1.46501L16.0711 0.0500126L9.00014 7.12201L1.92914 0.0500126L0.515137 1.46501Z"
                    fill="#BF953F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP 5】問診票の入力、クレジットカードならびに保険証の登録
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone9.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone10.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone11.publicURL} alt="" />
                </div>
              </div>
              <div className="mt-12 mx-auto w-fit-content">
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.515137 1.46501L9.00014 9.95001L17.4851 1.46501L16.0711 0.0500126L9.00014 7.12201L1.92914 0.0500126L0.515137 1.46501Z"
                    fill="#BF953F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4 md:mb-8">
            <div className="flex items-center px-4 py-4 bg-secondary-pink">
              <h5 className="md:text-lg">
                【STEP
                6】医師とのビデオ通話が終了後、診察・決済内容を確認いただけます
              </h5>
            </div>
            <div className="py-4 md:py-8">
              <div className="flex flex-col gap-y-8 items-center md:flex-row">
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone12.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone13.publicURL} alt="" />
                </div>
                <div className="mx-auto w-1/2 md:w-1/5">
                  <img src={data.iphone14.publicURL} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
