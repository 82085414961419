import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Hairremoval = ({ showHairremoval, setShowHairremoval }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowHairremoval(false)
    }
  }
  return (
    <>
      {showHairremoval ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowHairremoval(!showHairremoval)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/hairremoval-modal.jpg"
                alt="DSL ハンドピース　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  DSL ハンドピースとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  脱毛レーザーのスタンダード「ダイオードレーザー」と軟毛・白髪まで治療可能な「RF」の融合。
                  <br />
                  従来の脱毛レーザーは、光が表皮のメラニンに強く吸収されて、深部で拡散していくため、真皮層、皮下組織にまで及ぶ毛包全体に、十分にエネルギーが届かないことがあります。
                  <br />
                  さらに色黒の皮膚では火傷のリスクがあるため照射出力を上げられない。または色の薄い毛や軟毛はメラニン量が少ないため光エネルギーが吸収されにくいというように、そのような症例では十分な治療効果が得られないと言えます。
                  <br />
                  DSLハンドピースのRFエネルギーは、上図のように光とは異なる選択性を持ち、メラニン量に関わりなく、3~4mmの深さに到達し、毛の周囲組織にダメージを与えます。
                  <br />
                  レーザー脱毛で難しいとされる色の薄い毛や白髪に効果が確認されているだけでなく、軟毛やうぶ毛に対する効果が期待されています。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  医療脱毛はこんな方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・少ない治療回数で効果を得たい
                  <br />
                  ・肌のトラブルを最小限に抑えたい
                  <br />
                  ・デリケートな部分の脱毛をしたい
                  <br />
                  ・色黒なので脱毛できるか不安
                  <br />
                  ・敏感肌なので脱毛に抵抗がある
                  <br />
                  ・痛みが苦手で医療脱毛に抵抗がある
                  <br />
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  色の薄い毛、白髪、軟毛、産毛に対する効果も見られています。
                  <br />
                  また、RFエネルギーを利用することで必要以上にダイオードレーザーの出力をあげずに済むため、皮膚のダメージが少なく肌に優しい脱毛が可能です。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <div className="flex items-center justify-center my-6">
                  <StaticImage
                    src="../../content/assets/hairremoval-image.jpeg"
                    alt="フォトRF　機械　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・赤身が出ることがありますが、長くても1時間程度で、すぐにメイクも可能す。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・赤み、乾燥、ほてりが現れる場合がございます。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・施術後は肌が敏感になっているため、十分な保湿ケアやUVケアを心がけてください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
