import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";

export const Campaign = () => {
  const data = useStaticQuery(graphql`
    {
      lineIcon: file(relativePath: { eq: "line-icon.svg" }) {
        publicURL
      }
      instagramIcon: file(relativePath: { eq: "instagram-icon.svg" }) {
        publicURL
      }
      twitterIcon: file(relativePath: { eq: "twitter-icon.svg" }) {
        publicURL
      }
      facebookIcon: file(relativePath: { eq: "facebook-icon.svg" }) {
        publicURL
      }
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { slug: { eq: "campaign" } } } }
        }
        sort: { fields: [date], order: DESC }
      ) {
        edges {
          node {
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      quality: 100
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            id
            uri
            title
          }
        }
      }
    }
  `);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    className: "campaign-slide",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <section className="p-4 w-full bg-white md:py-8">
      <div className="mb-8">
        <h2 className="mb-1 text-center text-primary-gold text-2xl font-bold tracking-widest md:mb-2 md:text-3xl">
          CAMPAIGN
        </h2>
        <span className="block text-center text-sm tracking-widest">
          キャンペーン情報
        </span>
      </div>
      <div className="mx-auto max-w-2xl">
        <Slider {...settings}>
          {data.allWpPost.edges.map((edge) => {
            const post = edge.node;
            const image = getImage(post.featuredImage.node.localFile);
            return (
              <div className="mb-6 mx-4 w-full md:mb-0" key={post.id}>
                <Link
                  className="w-full hover:opacity-80 transform duration-300"
                  to={post.uri}
                >
                  <GatsbyImage
                    className="mb-4 w-full h-auto"
                    image={image}
                    alt={`${post.title} 画像`}
                  />
                  <h3>{post.title}</h3>
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="flex gap-x-4 items-center justify-center mt-8 md:gap-x-8 md:mt-16">
        <a href="https://lin.ee/Vw3HGc9" target="_blank" rel="noreferrer">
          <img
            className="w-12 h-12"
            src={data.lineIcon.publicURL}
            alt="LINE ロゴ"
          />
        </a>
        <a
          href="https://twitter.com/ebisubeauty2021"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-12 h-12"
            src={data.twitterIcon.publicURL}
            alt="twitter ロゴ"
          />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100077261290093"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-12 h-12"
            src={data.facebookIcon.publicURL}
            alt="facebook ロゴ"
          />
        </a>
        <a
          href="https://www.instagram.com/ebisubeauty2021/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-12 h-12"
            src={data.instagramIcon.publicURL}
            alt="instagram ロゴ"
          />
        </a>
      </div>
    </section>
  );
};
