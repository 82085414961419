import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Dermapen = ({ showDermapen, setShowDermapen }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowDermapen(false);
    }
  };
  return (
    <>
      {showDermapen ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowDermapen(!showDermapen)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/dermapen-modal.jpg"
                alt="ダーマペン4　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ダーマペン4とは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  極細の針を真皮層まで通し、創傷治癒力を利用して肌の再生を図る治療法です。
                  <br />
                  私たちの肌には、何かしらの刺激を受けて傷がつくと、元通りに治そうとする能力が備わっています。これが「創傷治癒力」です。
                  <br />
                  そして傷が治った肌は、元の肌よりも若く新しい組織で作られていきます。
                  <br />
                  ダーマペンを使用することで、お肌が本来もつ「お肌の傷を治そうとする力」を呼び起こすことができ、お肌全体の若返りが期待できます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ダーマペン4はこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・ニキビを治したい
                  <br />
                  ・ニキビ跡の凹凸が気になる
                  <br />
                  ・毛穴の開き、くすみが気になる
                  <br />
                  ・シミ、シワ、たるみが気になる
                  <br />
                  ・お肌のトラブルを解決したい
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  開き気味の毛穴を目立たなくさせたい方、ニキビ跡をキレイにしたい方、シワを改善したい方におすすめします。
                  <br />
                  針の長さが調整できるため個々の症状（お肌のトラブル、お悩み）に合った治療が可能です。
                  <br />
                  痛み、出血がほとんどなく、ダウンタイムが短いという特徴もあります。
                  <br />
                  ダーマペンは、1回の施術で効果を実感でき、繰り返し行うことでより効果が続きます。当院では、患者様のお悩みに合わせて適切な治療を提供しています。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ダーマペンを使ってお肌に小さな穴を開けると、お肌は傷を治す力（自然治癒力）を働かせて、元の状態に戻そうとします。
                  <br />
                  お肌の傷が治るまでの過程で、真皮の中では、お肌を美しく保つために必要な、コラーゲンやエラスチンなどが新たにつくり出されていきます。
                  <br />
                  これにより、ハリのあるお肌、ぷるぷるとしたお肌へと若返る効果が期待できますので、シワやタルミへの効果も期待できるのです。
                  <br />
                  また、ダーマペンで開けた穴に、お肌のうるおいやハリを取り戻すような「成長因子」を含む成分を浸透させることで、より美しい肌へと導いていきます。
                  <br />
                  当院の患者さまの場合、一回の治施術で効果を実感できることが多いようです。ダーマペンは、繰り返し治療を行うとより効果的です。個人差はありますが、1ヶ月後を目安に治療を受けると、効果が長く続きます。
                </p>
                <div className="flex items-center justify-center my-6 lg:w-5/6 mx-auto">
                  <StaticImage
                    src="../../content/assets/dermapen-image.jpeg"
                    alt="マイクロニードル　機械　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <p className="text-sm leading-relaxed md:text-base">
                  恵比寿美容皮膚科で使用するダーマペン4では、0.2mmと3.0mmのニードルが追加となり、従来よりも幅広い穿刺深度を選択できるようになりました。
                  <br />
                  中でも3.0㎜のニードルによる穿刺深度は、従来のダーマペンと比較すると最も深く、厚みのある「線維性組織」でも有効的な治療効果が期待できます。
                  <br />
                  目の周囲やデリケートゾーンの小じわ、ニキビ跡などの毛穴治療では、ニードルの深度を0.1mm単位で選択することも可能です。
                  <br />
                  恵比寿美容皮膚科では、お一人おひとりのお肌やシワの状態、ニキビ・傷跡の深さなどに応じ、16本のニードル、25段階の穿刺深度、5段階の穿刺速度の中から最適な方法で調整を行い、より良い結果が得られるように全力を尽くしております。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・痛みはほとんどありませんが、施術中に痛みを感じた場合は、遠慮なくお知らせください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・表面麻酔を行いますので、強い痛みは感じない方がほとんどですが、ごく細いとはいえ針を刺しますので、中にはチクチクするような刺激を感じる方がいらっしゃいます。麻酔アレルギーがある方は、カウンセリングにてお知らせください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・施術による針穴はとても小さな穴ですので、数時間くらいでふさがりますが、その間は赤味が出ることがあります。また、とても小さな傷ではありますが、傷が治りにくい方などは跡が残る可能性があります。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・ケロイド、皮膚炎がある方、重症の糖尿病、膠原病の方、金属アレルギーの方は施術をお控えください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・施術後に過度な日焼けをした場合、色素沈着が起こる場合がありますので、保湿剤や日焼け止めを使用し、紫外線には注意してください。ただし、施術後6時間は、保湿剤、日焼け止めは使用しないでください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・妊娠中、授乳中の方は、施術が出来ません。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・施術後12時間はお肌に何も塗れない為、日傘や帽子等ご持参ください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
