import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export const FixedButtons = () => {
  const data = useStaticQuery(graphql`
    {
      smartphoneIcon: file(relativePath: { eq: "smartphone-icon.svg" }) {
        publicURL
      }
      desktopIcon: file(relativePath: { eq: "desktop-icon.svg" }) {
        publicURL
      }
      lineIcon: file(relativePath: { eq: "line-icon.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <div className="flex fixed bottom-0 inset-x-0 z-20 lg:transform lg:bottom-1/2 lg:translate-y-1/2 border-t-2 border-primary-gold lg:border-0 lg:flex-col lg:right-4 lg:left-auto lg:gap-y-4">
      <a
        href="https://lin.ee/Vw3HGc9"
        target="_blank"
        rel="noreferrer"
        className="w-1/2 bg-white lg:w-28 lg:h-28 lg:rounded-lg filter drop-shadow-xl"
      >
        <div className="h-full flex items-center justify-center py-2 gap-x-2 lg:flex-col lg:gap-y-2">
          <img
            className="w-10 lg:w-14"
            src={data.lineIcon.publicURL}
            alt="友達追加"
          />
          <span className="text-green-600 font-sans font-bold lg:text-sm">
            LINE予約
          </span>
        </div>
      </a>
      <Link
        to="/online"
        className="w-1/2 bg-secondary-gold lg:w-28 lg:h-28 lg:rounded-lg filter drop-shadow-xl"
      >
        <div className="flex items-center justify-center py-2 gap-x-2 lg:flex-col lg:gap-y-2">
          <img
            className="w-12 lg:w-16"
            src={data.desktopIcon.publicURL}
            alt="スマートフォン　アイコン"
          />
          <span className="text-white font-sans font-bold lg:text-sm">
            オンライン診療
          </span>
        </div>
      </Link>
    </div>
  );
};
