import React from "react";
import { Link } from "gatsby";

export const Footer = () => {
  return (
    <footer className="w-full p-4 md:py-8">
      <div className="text-white flex flex-col items-center justify-center gap-y-1 mb-8">
        <span className="text-lg font-medium tracking-wider md:text-2xl">
          恵比寿美容皮膚科
        </span>
        <span className="text-xs font-medium tracking-wider md:text-sm">
          EBISU BEAUTY CLINIC
        </span>
      </div>
      <div className="md:flex gap-x-4 justify-between max-w-7xl mx-auto">
        <div className="w-full mb-8">
          <div className="w-80 h-72 md:w-96 md:h-80 mx-auto md:ml-0 md:mr-auto mb-4 lg:mb-8">
            <iframe
              title="ebisu-map"
              className="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.209407649846!2d139.7134347156913!3d35.64721198020218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bc2dcd510cb%3A0x1f964f006ac576d0!2z5oG15q-U5a-_576O5a6555qu6Iaa56eR!5e0!3m2!1sja!2sjp!4v1633358987572!5m2!1sja!2sjp"
              loading="lazy"
            ></iframe>
          </div>
          <span className="text-white">
            〒150-0013
            <br />
            東京都渋谷区恵比寿1丁目26-17
            <br />
            阿部ビル3F
            <br />
            <br />
            TEL. 03-6450-4531
            <br />
            FAX. 03-6450-4532
            <br />
            <br />
            診療時間：10:00〜19:00
            <br />
            定休日：不定休
          </span>
        </div>
        <div className="w-full">
          <span className="text-white md:text-lg md:block md:text-right">
            サイトマップ
          </span>
          <ul className="mt-2 flex flex-wrap items-end gap-x-4 gap-y-2 text-white md:mt-4 md:flex-col md:gap-x-8 md:gap-y-4">
            <li>
              <Link className="text-sm md:text-base underline" to="/">
                HOME
              </Link>
            </li>
            <li>
              <Link className="text-sm md:text-base underline" to="/about">
                クリニック紹介
              </Link>
            </li>
            <li>
              <Link className="text-sm md:text-base underline" to="/treatment">
                施術内容
              </Link>
            </li>
            <li>
              <Link
                className="text-sm md:text-base underline"
                to="/treatment#price"
              >
                料金表
              </Link>
            </li>
            <li>
              <Link className="text-sm md:text-base underline" to="/contact">
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link className="text-sm md:text-base underline" to="/online">
                オンライン診療
              </Link>
            </li>
            <li>
              <Link className="text-sm md:text-base underline" to="/news">
                お知らせ・ブログ
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full text-white text-xs md:text-sm text-center mt-8">
        Copyright ©︎ 2021 EBISU BEAUTY CLINIC.
      </div>
    </footer>
  );
};
