import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const NewsArchive = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      quality: 100
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            id
            uri
            title
            date(formatString: "Y年MM月DD日")
            excerpt
          }
        }
      }
    }
  `)
  return (
    <section className="bg-white px-6 py-8 md:px-8 lg:px-0">
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h3 className="text-center text-2xl font-bold text-primary-gold tracking-widest mb-1 md:text-3xl md:mb-2">
            NEWS・BLOG
          </h3>
          <h1 className="text-center block text-sm tracking-widest">
            お知らせ・スタッフブログ
          </h1>
        </div>
        <div className="flex flex-col gap-y-8">
          {data.allWpPost.edges.map(edge => {
            const post = edge.node
            const image = getImage(post.featuredImage.node.localFile)
            return (
              <div className="w-full md:mb-0" key={post.id}>
                <Link
                  className="w-full flex gap-x-4 hover:opacity-80 transform duration-300"
                  to={post.uri}
                >
                  <GatsbyImage
                    className="flex-shrink-0 w-24 h-24 bg-primary-gray lg:w-40 lg:h-40"
                    image={image}
                    alt={`${post.title} 画像`}
                  />
                  <div className="flex-1 w-full">
                    <h4 className="font-semibold tracking-wider underline lg:mb-1 md:text-lg">
                      {post.title}
                    </h4>
                    <span className="text-primary-gold font-bold text-xs lg:text-sm">{`${post.date} | お知らせ`}</span>
                    <p
                      className="text-sm lg:mt-2"
                      dangerouslySetInnerHTML={{
                        __html: `${post.excerpt}`,
                      }}
                    />
                  </div>
                </Link>
                <div className="w-full h-px bg-primary-gold my-4" />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
