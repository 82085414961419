import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Injections = ({ showInjections, setShowInjections }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowInjections(false);
    }
  };
  return (
    <>
      {showInjections ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowInjections(!showInjections)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/injections-modal.jpeg"
                alt="注射系　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ヒアルロン酸注入とは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  <span className="font-bold">
                    ジュビダームビスタ®ボリューマXCは、
                  </span>
                  <br />
                  シリーズの中でも低吸水性で硬いヒアルロン酸で、お肌を持ち上げる力が強いので、シワを伸ばしてたるみを引き上げるなどの治療に、また顔のくぼみ・凹みの改善にも用います。
                  <br />
                  <br />
                  <span className="font-bold">
                    ジュビダームビスタ®ボルベラXCは、
                  </span>
                  <br />
                  持続時間が高いにも関わらず非常に柔らかく馴染みやすいのが特徴の製剤です。
                  <br />
                  これはヒアルロン酸製剤の人気の理由である“自然な仕上がり”を造り上げることに大きく関わっています。
                  <br />
                  皮膚の薄い部位の表層への注入にも効果的なので、下瞼をはじめ、お顔の細かいくぼみの修正にも適していることが他のヒアルロン酸製剤よりも優位な点と言えます。
                  <br />
                </p>
                <div className="h-full w-2/3 my-6 mx-auto">
                  <StaticImage
                    className="h-full"
                    src="../../content/assets/compare-hyaluronic-acid.jpeg"
                    alt="ヒアルロン酸　比較画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ボトックス（Allergan）
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ボトックス注射とは、ボツリヌス菌から生産されるタンパク質の「Ａ型ボツリヌス毒素」のことで、筋肉と神経の結合部に作用し、局所的に筋肉の動きを弱める働きがあります。
                  <br />
                  眉間・目尻・額・あごなどの、表情じわの改善などに非常に効果を発します。
                  <br />
                  注入後２日から１週間ほどで効果が表れ、およそ6ヶ月ほど持続します。
                  <br />
                  <br />
                  ～国内で唯一の承認薬、施中資格を取得～
                  <br />
                  ボトックスビスタは、国内で唯一製造販売承認を取得している、アラガン社製のボツリヌス菌製剤です。
                  <br />
                  世界80ヶ国以上で治療承認を取得し、世界中で一千万人以上の方がボトックスビスタの美容治療を受けています。
                  <br />
                  他社製剤との臨床比較試験でも、しわの改善症例や持続期間など、ボトックスビスタのほうが優れているという結果が報告されています。
                  <br />
                  安全性に関しても、効果に関しても、世界中で最も支持をされている薬剤だということなのです。
                  <br />
                  当院では、適切な保管・運送体制が保証された、日本国内向けの正規品であるボトックスビスタを、アラガン・ジャパン株式会社より直接購入しています。
                  <br />
                  <br />
                  当院のドクターは「VST認定医」です。
                  <br />
                  ボトックスビスタを使用するには、講習・実技セミナーを受講し、施注資格を取得する必要があります。
                  <br />
                  VSTとは、ボトックスビスタ®の製造元アラガン社が定めている認定医制度のことです。
                  <br />
                  注入技術を高めるよう、定期的に行われる研修やセミナーに参加しています。
                  <br />
                  カウンセリングも注入も、同じドクターで行いますのでご安心ください。
                  <br />
                </p>
                <div className="h-full w-fit-content my-6 mx-auto">
                  <StaticImage
                    className="h-full"
                    src="../../content/assets/botox-allergan.png"
                    alt="ヒアルロン酸　比較画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ボツラックス
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  美容において、主に表情ジワの改善効果で⼈気の⾼いボツリヌストキシン製剤。
                  <br />
                  ボツラックスは厚⽣労働省認可で著名なボトックスと同様のA型ボツリヌストキシン製剤で、韓国ヒューゲル社製の製剤です。
                  <br />
                  KFDA（韓国⾷品医薬品安全庁）に医薬承認されており、効果と安全性を認められています。ボツリヌストキシンは、ボツリヌス菌から抽出されたタンパク質の一種で、アセチルコリン分泌阻害のために一過性の筋肉麻痺を生じさせます。
                  <br />
                  <br />
                  以前から、眼科、神経内科の領域で、眼瞼・顔面痙攣などの治療に用いられてきました。
                  <br />
                  男女を問わず非常に多くの人々から支持されているプチ整形です。
                  <br />
                  <br />
                  眉間や額、目尻のシワなどの表情皺では、皮膚に付着した筋肉(表情筋）の収縮がその発生に大きく関与しているため、手術などの従来の方法では治療が難しいとされていました。しかし、ボツリヌストキシンを表情筋に注入して麻痺させることで、こうしたシワを軽減・消失させることが可能です。
                  <br />
                  <br />
                  近年ボツリヌストキシンは、様々な医療の可能性が注目され、世界中で研究されています。このボツリヌストキシンの有効な特性を活かし、腋臭症や、ワキガ、多汗症などの治療にも広く利用されています。
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
