import React from "react"
import { FixedButtons } from "./FixedButtons"
import { Footer } from "./Footer"
import { Header } from "./Header"

export const Container = ({ children }) => {
  return (
    <main className="bg-primary-pink">
      <FixedButtons />
      <Header />
      {children}
      <Footer />
    </main>
  )
}
