import React from "react";

export const Price = () => {
  return (
    <section id="price" className="mx-auto max-w-5xl">
      <div className="mb-8 mx-auto w-fit-content">
        <h3 className="mb-1 text-primary-gold text-xl font-bold tracking-widest md:mb-2 md:text-2xl">
          料金表
        </h3>
        <div className="w-full h-px bg-primary-gold" />
      </div>
      <div className="mb-8 px-4 md:px-8">
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">お支払い方法</h5>
          </div>
          <div className="flex items-center py-4 border-b border-primary-gray">
            <span className="text-sm md:text-base">
              現金、クレジットカード（VISA, Mastercard, JCB, American
              Express）、電子マネー（Apple Pay, iD,
              QUICPay）がご利用いただけます。また、分割払いにも対応しております。
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【しわ・たるみ】HIFU（ウルセラリフト）【ドクター照射】
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【全顔】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥166,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【全顔 + 顎下】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥187,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ウルセラアイリフト】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥121,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ウルセラチークリフト】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥143,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">【ハリ・透明感】プラズマシャワー</h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【ビタミンC】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥16,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ナビジョン（トラネキサム酸）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥18,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥25,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ホワイトパール】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥25,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【完全毛穴レス・うるつや卵肌】マイクロニードル高周波RF
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔】※成長因子パック付き
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥37,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【頬 + 鼻 or 額 + 眉間】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥30,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔 ASCE塗布】※SRSパック付き
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥52,000
              <br />
              <span className="text-red-500">【初回お試し】¥49,000</span>
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔3回コース】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥99,900
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔 ASCE塗布 3回コース】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥149,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・サリチル酸ピーリング
              <br />
              ・マッサージピール
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥5,000
              <br />
              ¥6,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">【しわ・小じわ・肌老化】ダーマペン4</h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【成長因子パック】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥24,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【CLR】アクティブなニキビに
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥27,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥35,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【CLR 3回（ヒアルロン酸パック付き）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥76,950
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム） 3回】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥99,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・成長因子パック
              <br />
              ・パック持ち帰り
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥3,000
              <br />
              ¥2,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【ダーマペン × マッサージピール】ヴェルヴェットスキン
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔（麻酔代込）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥26,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・ASCE+（エクソソーム）
              <br />
              ・成長因子パック
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥12,000
              <br />
              ¥3,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">【ダーマペン × ミラノリピール】</h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【全顔（麻酔代込）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥29,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・ASCE+（エクソソーム）
              <br />
              ・成長因子パック
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥12,000
              <br />
              ¥3,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【ハリ・ツヤ小じわ・ニキビ・毛穴】ミラノリピール
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【全顔】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥21,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・成長因子パック
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥3,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【ハリ・ツヤ小じわ】マッサージピール × プラズマシャワー
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【ビタミンC】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥21,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ナビジョン（トラネキサム酸）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥23,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥30,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ホワイトパール】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥24,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              ※4回コース 5パーセントオフ
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【ニキビ・毛穴・くすみ】サリチル酸マクロゴールピーリング ×
              プラズマシャワー
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【ビタミンC】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥19,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ナビジョン（トラネキサム酸）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥21,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥28,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ホワイトパール】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥22,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">ミラノリピール × プラズマシャワー</h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【ビタミンC】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥24,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ナビジョン（トラネキサム酸）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥26,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ASCE+（エクソソーム）】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥33,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ホワイトパール】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥27,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【しわ・ほうれい線・涙袋】ヒアルロン酸 ～アラガン社製～
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              ボリューマ・ボルベラ【1本 → 1cc】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥66,000
              <br />
              2本目以降
              <br />
              ¥58,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              アラガン ボラックス【1本 → 1cc】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥68,000
              <br />
              2本目以降
              <br />
              ¥58,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【目の下に特化】ヒアルロン酸 ～リデンシティーⅡ～
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【1本 → 1cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥64,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              ※ご希望の場合は、マイクロカニューレ￥2,750、麻酔￥3,300が別途かかります。
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center justify-between px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【しわ・小顔・多汗】ボツリヌス注射 ～ボツラックス～
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【口角、顎、鼻根、ガミースマイル、人中短縮】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥7,700
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【眉間】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥8,800
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【額・目尻】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥12,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【エラ】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥28,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【脇】</span>
            <span className="block w-full text-right text-sm md:text-base">
              60単位　¥35,000
              <br />
              80単位　¥38,000
              <br />
              100単位　¥41,000
              <br />
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【肩】</span>
            <span className="block w-full text-right text-sm md:text-base">
              50単位　¥32,000
              <br />
              80単位　¥36,000
              <br />
              100単位　¥39,000
              <br />
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center justify-between px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【しわ・小顔・多汗】ボツリヌス注射
              ～アラガン社製・ボトックスビスタ～
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【口角、顎、鼻根、ガミースマイル、人中短縮】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥18,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【眉間】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥22,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【額・目尻】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥26,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【エラ】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥42,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【脇】</span>
            <span className="block w-full text-right text-sm md:text-base">
              60単位　¥45,000
              <br />
              80単位　¥52,000
              <br />
              100単位　¥59,000
              <br />
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【肩】</span>
            <span className="block w-full text-right text-sm md:text-base">
              50単位　¥42,000
              <br />
              80単位　¥52,000
              <br />
              100単位　¥59,000
              <br />
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【肌再生治療、くま、皺、ハリ】スネコス注射
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【1本（3cc）】
              <br />
              【2本セット】
              <br />
              【4本セット】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥42,000
              <br />
              ¥75,600
              <br />
              ¥134,400
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【ハリ、引き締め、抗酸化】リデンシティーⅠ注射
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【1cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥42,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【3cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥62,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【明るさ、滑らかさ、ハリ】ネオファウンド注射
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【1本（3cc）】
              <br />
              【2本セット】
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥49,000
              <br />
              ¥88,200
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【OPTION】
              <br />
              ・マッサージピール
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              <br />
              ¥6,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">
              【再生能力、抗炎症作用】ASCE+ エクソソーム手打ち
            </h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【3cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥37,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【5cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥59,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              ※麻酔代別途¥3,300がかかります。
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">【脂肪溶解注射】カベリン注射</h5>
          </div>
          {/* <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【チンセラ 4cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥34,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【チンセラ 8cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥54,000
            </span>
          </div> */}
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【4cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥29,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">【8cc】</span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥49,000
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8" id="medicines">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">内服薬</h5>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【飲むダイエット】
              <br />
              メトホルミン
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              60錠　¥5,500
              <br />
              100錠　¥9,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【飲む美肌治療】
              <br />
              シナール・ユベラ
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              【1ヶ月】　¥3,700
              <br />
              1日3錠
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【飲むニキビ治療】
              <br />
              ビオチン散・シナール・ピドキサール
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              【1ヶ月】　¥5,500
              <br />
              1日3錠
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ニキビ治療】
              <br />
              ビオチン散（粉）
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              【1ヶ月】　¥1,430
              <br />
              1日1袋
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              タオチン（1週間分）
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥1,540
              <br />
              1日3錠
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              クラリス（1週間分）
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥1,540
              <br />
              1日2錠
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【ハイドロキノン4%】
              <br />
              Lico Forte
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥5,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【トレチノイン0.05%】
              <br />
              Retachyl Cream
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥5,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              【トレチノイン0.1%】
              <br />
              Acnelyse Cream
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥6,600
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <div className="flex items-center px-4 py-4 bg-secondary-pink">
            <h5 className="md:text-lg">注射・点滴</h5>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              高濃度ビタミンC点滴12.5mg
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥6,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              グルタチオン600 + αリポ酸 + 強ミノ　注射
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥6,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              グルタチオン600 + αリポ酸 + 強ミノ　注射
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥8,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              4回チケット　10%オフ
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              施術とセットでもっとお得
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              高濃度ビタミンC点滴12.5mg
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥3,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">
              グルタチオン600 + αリポ酸 + 強ミノ　注射
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥4,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              グルタチオン600 + αリポ酸 + 強ミノ　注射
            </span>
            <span className="block w-full text-right text-sm md:text-base">
              ¥6,500
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose">
            <span className="w-full text-sm md:text-base">プラセンタ注射</span>
            <span className="block w-full text-right text-sm md:text-base">
              1アンプル　¥1,400
              <br />
              追加毎に　¥1,000
            </span>
          </div>
          <div className="flex items-center py-4 leading-loose border-b border-primary-gray">
            <span className="w-full text-sm md:text-base">
              ※施術とセットで1本¥700
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
