import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Cosmetics = ({ showCosmetics, setShowCosmetics }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowCosmetics(false)
    }
  }
  return (
    <>
      {showCosmetics ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowCosmetics(!showCosmetics)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/cosmetics-modal.jpeg"
                alt="化粧品　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ゼオスキンとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ゼオスキンヘルスは、皮膚科医としても世界で名声が高いゼイン・オバジによって作られた医療機関向けの基礎化粧品です。
                  <br />
                  ゼイン・オバジが35年以上にわたる研究から生み出した、「ZO SKIN
                  HEALTH Circle」を元に開発されました。
                  <br />
                  <br />
                  一人ひとりに合ったスキンケアで、美しく健康的な肌へ導きます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  エムディアとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  美肌治療とEGF(上皮細胞増殖因子）がもたらす効果に着目し、治療との相乗効果によって肌悩みに働きかける化粧品エムディア（M-Dear）が誕生。
                  <br />
                  患者様のニーズにお応えできるよう肌の専門家によるご意見をもとに研究・試作を重ね、進化し続けています。
                  <br />
                  エムディア(M-Dear）は、皮膚科学に基づいて正確に判断し、肌状態に合った適切なスキンケアが「理想的な肌」への近道であると考えています。
                  <br />
                  <br />
                  美肌治療、紫外線、乾燥、摩擦などの様々な刺激から肌を守るため、香料、着色料、紫外線吸収剤、エタノール、パラベンは使用せず、機能性や相乗効果に優れたスキンケアであることにこだわったエイジングケアラインです。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  WiQoとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  WiQoは、イタリアのGPO
                  Srl社が販売しているスキンケア製品のシリーズです。
                  <br />
                  皮膚科医が開発に携わったドクターズコスメで、すべての肌質に合うスキンケア製品として開発されました。
                  <br />
                  日本では医療機関の専売製品のため、皮膚科などでのみ、購入することができます。
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
