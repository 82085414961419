import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import {
  Acneneedle,
  Cosmetics,
  Dermapen,
  Hairremoval,
  Hifu,
  Injections,
  Massagepeel,
  Microneedle,
  Photorf,
  Plasma,
  Velvetskin,
} from "../modal";

export const Treatment = () => {
  const [toggleRecommend, setToggleRecommend] = useState(false);
  const [toggleRecommend02, setToggleRecommend02] = useState(false);
  const [toggleRecommend03, setToggleRecommend03] = useState(false);
  const [toggleRecommend04, setToggleRecommend04] = useState(false);
  const [toggleRecommend05, setToggleRecommend05] = useState(false);
  const [toggleRecommend06, setToggleRecommend06] = useState(false);
  const [toggleRecommend07, setToggleRecommend07] = useState(false);
  const [toggleRecommend08, setToggleRecommend08] = useState(false);

  // ハイフ
  const [showHifu, setShowHifu] = useState(false);
  const openHifu = () => {
    setShowHifu((prev) => !prev);
  };

  // フォトRF
  const [showPhotorf, setShowPhotorf] = useState(false);
  const openPhotorf = () => {
    setShowPhotorf((prev) => !prev);
  };

  // プラズマ
  const [showPlasma, setShowPlasma] = useState(false);
  const openPlasma = () => {
    setShowPlasma((prev) => !prev);
  };

  // マイクロニードル
  const [showMicroneedle, setShowMicroneedle] = useState(false);
  const openMicroneedle = () => {
    setShowMicroneedle((prev) => !prev);
  };

  // RFアクネ針治療
  const [showAcneneedle, setShowAcneneedle] = useState(false);
  const openAcneneedle = () => {
    setShowAcneneedle((prev) => !prev);
  };

  // ダーマペン4
  const [showDermapen, setShowDermapen] = useState(false);
  const openDermapen = () => {
    setShowDermapen((prev) => !prev);
  };

  // ヴェルヴェットスキン
  const [showVelvetskin, setShowVelvetskin] = useState(false);
  const openVelvetskin = () => {
    setShowVelvetskin((prev) => !prev);
  };

  // マッサージピール
  const [showMassagepeel, setShowMassagepeel] = useState(false);
  const openMassagepeel = () => {
    setShowMassagepeel((prev) => !prev);
  };

  // 各種注射系
  const [showInjections, setShowInjections] = useState(false);
  const openInjections = () => {
    setShowInjections((prev) => !prev);
  };

  // 内服薬
  const [showMedicines, setShowMedicines] = useState(false);
  const openMedicines = () => {
    setShowMedicines((prev) => !prev);
  };

  // 化粧品
  const [showCosmetics, setShowCosmetics] = useState(false);
  const openCosmetics = () => {
    setShowCosmetics((prev) => !prev);
  };

  // 医療脱毛
  const [showHairremoval, setShowHairremoval] = useState(false);
  const openHairremoval = () => {
    setShowHairremoval((prev) => !prev);
  };

  return (
    <section>
      <div className="hidden lg:block relative max-w-6xl mx-auto h-112 mb-12 px-8">
        <StaticImage
          className="w-full h-full rounded-xl border-4 border-primary-pink"
          src="../../content/assets/treatment-banner.jpeg"
          alt="施術内容　画像"
          placeholder="blurred"
          quality="100"
        />
      </div>
      <div className="mb-8 md:mb-16">
        <h3 className="text-center text-2xl font-bold text-primary-gold tracking-widest mb-1 md:text-3xl md:mb-2">
          TREATMENT
        </h3>
        <h1 className="text-center block text-sm tracking-widest">
          施術メニュー・料金表
        </h1>
      </div>
      <div className="max-w-5xl mx-auto">
        <div className="w-fit-content mx-auto mb-8">
          <h3 className="text-xl font-bold text-primary-gold tracking-widest mb-1 md:text-2xl md:mb-2">
            診察料
          </h3>
          <div className="w-full h-px bg-primary-gold" />
        </div>
        <div className="mb-4 px-4 md:px-8">
          <div className="flex items-center bg-secondary-pink py-4">
            <span className="w-1/2 text-center md:text-lg">診察料（医師）</span>
            <span className="w-1/2 text-center md:text-lg">
              カウンセリング料
            </span>
          </div>
          <div className="flex items-center py-4">
            <span className="w-1/2 text-center md:text-lg">
              ¥5,000
              <br />
              <span className="text-xs md:text-sm">
                （診察のみご希望の場合）
              </span>
            </span>
            <span className="w-1/2 text-center md:text-lg">
              無<br />料
            </span>
          </div>
        </div>
        <p className="tracking-wider text-center mb-16 px-4 md:px-8">
          当日予約も対応しております。お気軽に
          <span className="text-primary-gold font-bold underline">
            <Link to="/contact">お問い合わせ</Link>
          </span>
          ください。
        </p>
        <div className="w-fit-content mx-auto mb-8">
          <h3 className="text-xl font-bold text-primary-gold tracking-widest mb-1 md:text-2xl md:mb-2">
            施術メニュー
          </h3>
          <div className="w-full h-px bg-primary-gold" />
        </div>
        <div className="mb-8 md:px-8 lg:mb-16">
          <Link
            to="/treatment/hifu"
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/ulthera-menu.jpeg"
              alt="HIFU（ウルセラ）　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  HIFU（ウルセラ）
                </h6>
                <span className="block w-fit-content text-secondary-gray font-sans text-sm md:text-base">
                  たるみ治療・リフトアップ
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </Link>
          <button
            onClick={openPlasma}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/plasma-menu.jpeg"
              alt="プラズマシャワー　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  プラズマシャワー
                </h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  ハリ・透明感
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button
            onClick={openMicroneedle}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/microneedle-menu.jpeg"
              alt="マイクロニードル高周波RF　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  マイクロニードル高周波RF
                </h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  完全毛穴レス・うるつや卵肌
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button
            onClick={openDermapen}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/dermapen-menu.jpg"
              alt="ダーマペン4　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  ダーマペン4（エクソソーム、CLR）
                </h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  しわ・小じわ・肌老化
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button
            onClick={openVelvetskin}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/velvetskin-menu.jpeg"
              alt="ヴェルヴェットスキン　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  ヴェルヴェットスキン
                </h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  肌質改善
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button
            onClick={openMassagepeel}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/massagepeel-menu.jpeg"
              alt="マッサージピール　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  マッサージピール
                </h6>
                <span className="block w-fit-content text-secondary-gray font-sans text-sm md:text-base">
                  【即効性】ハリ・弾力
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button
            onClick={openCosmetics}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/cosmetics-menu.jpeg"
              alt="化粧品　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">化粧品</h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  ゼオスキン・エムディア・ワイコ
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <button className="w-full border-t border-primary-gold md:flex">
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/sunekos-menu.jpeg"
              alt="スネコス・ネオファウンド・リデンシティーⅠ　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">
                  スネコス・ネオファウンド・リデンシティーⅠ
                </h6>
              </div>
            </div>
          </button>
          <button className="w-full border-t border-primary-gold md:flex">
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/kabelline-menu.jpeg"
              alt="カベリン　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">カベリン</h6>
              </div>
            </div>
          </button>
          <button
            onClick={openInjections}
            className="w-full border-t border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/injections-menu.jpeg"
              alt="注射系　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">各種注射系</h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  スネコス注射・ヒアルロン酸・ボトックス...
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </button>
          <Link
            to="#medicines"
            className="w-full border-t border-b border-primary-gold md:flex"
          >
            <StaticImage
              className="w-full h-48 object-cover md:w-2/5"
              src="../../content/assets/medicine.jpeg"
              alt="内服薬　画像"
              placeholder="blurred"
              quality="100"
            />
            <div className="flex items-center justify-between p-4 md:w-3/5 md:h-48">
              <div className="tracking-wider">
                <h6 className="w-fit-content mb-2 md:text-xl">内服薬</h6>
                <span className="w-fit-content block text-secondary-gray font-sans text-sm md:text-base">
                  飲むダイエット・飲む美肌治療...
                </span>
              </div>
              <div>
                <span className="text-primary-gold font-xl">〉</span>
              </div>
            </div>
          </Link>
        </div>
        <div>
          {/* <div className="px-4 mb-8 md:px-8">
            <div className="flex items-center bg-secondary-pink px-4 py-4">
              <h5 className="w-fit-content text-center md:text-lg">
                【EBC限定】人気プラン
                <div className="w-full h-px bg-primary-gold mt-1" />
              </h5>
            </div>
            <button
              className={`${
                toggleRecommend ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend(!toggleRecommend)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                最先端美容機器によるスキンケア
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:flex items-center flex-row-reverse gap-x-8">
                <div className="md:w-1/2 h-full">
                  <StaticImage
                    src="../../content/assets/plasma-recommended.jpeg"
                    alt="最先端美容機器によるスキンケア　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <div className="md:w-1/2">
                  <h5 className="my-4 text-xl font-bold">
                    プラズマシャワー + マイクロニードル高周波RF + 高濃度パック
                  </h5>
                  <p className="text-sm md:text-base leading-relaxed">
                    プラズマによる滅菌力で皮膚トラブルを引き起こす細菌を死滅させ、TDDS（経皮ドラッグデリバリーシステム）による皮膚細胞を結合している細胞溶着分子を切断することで如何なる薬剤も驚異的に浸透率をUPすることが実現可能となります。
                    <br />
                    プラズマによる高い美白効果と、超高濃度薬剤パックを組み合わせることにより、さらに高い美肌＆若返り効果を得られることができます。
                  </p>
                </div>
              </div>
            </div>
            <button
              className={`${
                toggleRecommend02 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend02(!toggleRecommend02)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                ニキビの悩みから解放。徹底アクネ治療
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend02 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend02 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:flex items-center flex-row-reverse gap-x-8">
                <div className="md:w-1/2 h-full">
                  <StaticImage
                    src="../../content/assets/acne-recommended.jpeg"
                    alt="最先端美容機器によるスキンケア　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <div className="md:w-1/2">
                  <h5 className="my-4 text-xl font-bold">
                    より早く、よりきれいにニキビを治したい方におすすめ
                  </h5>
                  <p className="text-sm md:text-base leading-relaxed">
                    EBCでは、今あるニキビを治すだけでなく、ニキビ跡を残さない、さらにはニキビを繰り返さない肌質へと根本から改善することを目的に治療をします。
                  </p>
                </div>
              </div>
            </div>

            <button
              className={`${
                toggleRecommend03 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend03(!toggleRecommend03)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                HIFU（ウルセラ）によるフェイスリフトをどこよりもお安くご提供
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend03 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend03 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:flex items-center flex-row-reverse gap-x-8">
                <div className="md:w-1/2 h-full">
                  <StaticImage
                    src="../../content/assets/hifu-recommended.jpeg"
                    alt="最先端美容機器によるスキンケア　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <div className="md:w-1/2">
                  <h5 className="my-4 text-xl font-bold">
                    ほうれい線改善・たるみUP・小顔
                  </h5>
                  <p className="text-sm md:text-base leading-relaxed">
                    HIFU（高強度集束超音波）という技術を用い、これまでの機器治療では不可能だった、深い筋膜層（SMAS）からの引き上げを可能にした最新のたるみ治療です。機器治療の中では最大のリフト効果があり、半年から1年ほど効果が継続します。
                    <br />
                    手術とは異なり、メスを使いませんので、傷は残らず、ダウンタイムもほとんどない治療で、直後から通常の生活をすることができます。また、目元などデリケートな部位の小じわやたるみにも一定の効果があります。
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="px-4 mb-8 md:px-8">
            <div className="flex items-center bg-secondary-pink px-4 py-4">
              <h5 className="w-fit-content text-center md:text-lg">
                お悩み別メニュー
                <div className="w-full h-px bg-primary-gold mt-1" />
              </h5>
            </div>
            <button
              className={`${
                toggleRecommend04 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend04(!toggleRecommend04)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                しわ・たるみを取りたい
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend04 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend04 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:grid grid-cols-2 items-center gap-8">
                <div className="w-full h-full rounded-full flex-shrink-0 mb-8 md:mb-0">
                  <button
                    onClick={openHifu}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/ulthera-menu.jpeg"
                      alt="HIFU（ウルセラ）　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      HIFU（ウルセラ）
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full flex-shrink-0">
                  <button
                    onClick={openInjections}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/gouri-menu.jpeg"
                      alt="GOURI　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      各種注射系
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <button
              className={`${
                toggleRecommend05 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend05(!toggleRecommend05)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                美白・美肌になりたい（肌質改善）
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend05 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend05 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:grid grid-cols-2 items-center gap-8">
                <div className="w-full h-full rounded-full mb-8 md:mb-0">
                  <button
                    onClick={openMicroneedle}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/microneedle-menu.jpeg"
                      alt="マイクロニードル高周波RF　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      マイクロニードル高周波RF
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full mb-8 md:mb-0">
                  <button
                    onClick={openPlasma}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/plasma-menu.jpeg"
                      alt="プラズマシャワー　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      プラズマシャワー
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full">
                  <button
                    onClick={openPhotorf}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/photorf-menu.jpeg"
                      alt="フォトRF　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      フォトRF
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <button
              className={`${
                toggleRecommend06 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend06(!toggleRecommend06)}
            >
              <h5 className="w-5/6 md:text-lg text-left">
                ニキビ・ニキビ跡を治したい
              </h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend06 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend06 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:grid grid-cols-2 items-center gap-8">
                <div className="w-full h-full rounded-full flex-shrink-0 mb-8 md:mb-0">
                  <button
                    onClick={openDermapen}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/dermapen-menu.jpg"
                      alt="ダーマペン4　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      ダーマペン4
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full flex-shrink-0">
                  <button
                    onClick={openVelvetskin}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/velvetskin-menu.jpg"
                      alt="ヴェルヴェットスキン　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      ヴェルヴェットスキン
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full mb-8 md:mb-0">
                  <button
                    onClick={openMicroneedle}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/microneedle-menu.jpeg"
                      alt="マイクロニードル高周波RF　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      マイクロニードル高周波RF
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full mb-8 md:mb-0">
                  <button
                    onClick={openAcneneedle}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/skinneedle-menu.jpg"
                      alt="RFアクネ針治療　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      RFアクネ針治療
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <button
              className={`${
                toggleRecommend07 ? "" : "border-b border-primary-gold"
              } w-full flex items-center justify-between px-4 py-6`}
              onClick={() => setToggleRecommend07(!toggleRecommend07)}
            >
              <h5 className="w-5/6 md:text-lg text-left">脱毛したい</h5>
              <div className="flex-shrink-0 w-7 h-7 bg-primary-gold flex items-center justify-center rounded-full">
                <span className="text-white font-bold text-xl whitespace-nowrap">
                  {`${toggleRecommend07 ? "－" : "＋"}`}
                </span>
              </div>
            </button>
            <div
              className={`${
                toggleRecommend07 ? "border-b border-primary-gold" : "hidden"
              } w-full p-4 tracking-wider`}
            >
              <div className="md:grid grid-cols-2 items-center gap-8">
                <div className="w-full h-full rounded-full flex-shrink-0 mb-8 md:mb-0">
                  <button
                    onClick={openHairremoval}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/hairremoval-woman-menu.jpg"
                      alt="医療脱毛【レディース】　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      医療脱毛【レディース】
                    </span>
                  </button>
                </div>
                <div className="w-full h-full rounded-full flex-shrink-0">
                  <button
                    onClick={openHairremoval}
                    className="w-full text-white text-lg"
                  >
                    <StaticImage
                      className="w-full h-full rounded-full mb-4 md:mb-8"
                      src="../../content/assets/hairremoval-men-menu.jpg"
                      alt="医療脱毛【メンズ】　画像"
                      placeholder="blurred"
                      quality="100"
                    />
                    <span className="py-2 bg-primary-gold w-5/6 mx-auto block text-center rounded-full">
                      医療脱毛【メンズ】
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Hifu showHifu={showHifu} setShowHifu={setShowHifu} />
      <Photorf showPhotorf={showPhotorf} setShowPhotorf={setShowPhotorf} />
      <Plasma showPlasma={showPlasma} setShowPlasma={setShowPlasma} />
      <Microneedle
        showMicroneedle={showMicroneedle}
        setShowMicroneedle={setShowMicroneedle}
      />
      <Acneneedle
        showAcneneedle={showAcneneedle}
        setShowAcneneedle={setShowAcneneedle}
      />
      <Dermapen showDermapen={showDermapen} setShowDermapen={setShowDermapen} />
      <Velvetskin
        showVelvetskin={showVelvetskin}
        setShowVelvetskin={setShowVelvetskin}
      />
      <Massagepeel
        showMassagepeel={showMassagepeel}
        setShowMassagepeel={setShowMassagepeel}
      />
      <Hairremoval
        showHairremoval={showHairremoval}
        setShowHairremoval={setShowHairremoval}
      />
      <Cosmetics
        showCosmetics={showCosmetics}
        setShowCosmetics={setShowCosmetics}
      />
      <Injections
        showInjections={showInjections}
        setShowInjections={setShowInjections}
      />
    </section>
  );
};
