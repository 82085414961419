import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"

export const AboutHome = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
  }
  return (
    <section className="w-full p-4 md:py-8">
      <div className="mb-8">
        <h3 className="text-center text-2xl font-bold text-white tracking-widest mb-1 md:text-3xl md:mb-2">
          ABOUT US
        </h3>
        <span className="text-center block text-sm tracking-widest">
          恵比寿美容皮膚科について
        </span>
      </div>
      <div className="md:flex gap-x-8 items-center max-w-7xl mx-auto mb-8">
        <div className="md:w-2/5 flex flex-col items-center">
          <h1 className="tracking-widest leading-extra-loose text-center mb-8 lg:text-lg lg:leading-extra-loose lg:mb-16">
            より美しく、より若々しく
            <br />
            高いクオリティを求めるあなたへ
            <br />
            恵比寿から徒歩5分の非日常空間へ
            <br />
            ぜひお越しください。
          </h1>
          <Link
            to="/about"
            className="bg-primary-gold rounded-full px-16 py-2 text-white w-fit-content mx-auto mb-8 hover:opacity-80 transform duration-300 hover:opacity-80 transform duration-300"
          >
            詳しく見る →
          </Link>
        </div>
        <div className="w-full h-56 mx-auto md:w-3/5 md:h-80 lg:h-120">
          <Slider {...settings}>
            <StaticImage
              className="w-full h-56 oject-cover md:h-80 lg:h-120"
              src="../../content/assets/ebisu-clinic-01.jpeg"
              alt="クリニック画像"
              placeholder="blurred"
              quality="100"
            />
            <StaticImage
              className="w-full h-56 oject-cover md:h-80 lg:h-120"
              src="../../content/assets/ebisu-clinic-02.jpeg"
              alt="クリニック画像"
              placeholder="blurred"
              quality="100"
            />
            <StaticImage
              className="w-full h-56 oject-cover md:h-80 lg:h-120"
              src="../../content/assets/ebisu-clinic-03.jpeg"
              alt="クリニック画像"
              placeholder="blurred"
              quality="100"
            />
            <StaticImage
              className="w-full h-56 oject-cover md:h-80 lg:h-120"
              src="../../content/assets/ebisu-clinic-04.jpeg"
              alt="クリニック画像"
              placeholder="blurred"
              quality="100"
            />
          </Slider>
        </div>
      </div>
      {/* <div className="md:flex items-center justify-center gap-x-8">
        <Link className="hover:opacity-80 transform duration-300" to="/blog">
          <div className="bg-white rounded-full px-8 py-3 text-center mb-4 h-40 flex flex-col items-center justify-center md:mb-0">
            <h4 className="text-primary-gold font-semibold mb-2 text-xl">
              お知らせ・ブログ
            </h4>
            <span className="text-sm lg:text-base">
              お肌のお悩み関する記事やお得な情報を更新しております。
            </span>
          </div>
        </Link>
        <Link className="hover:opacity-80 transform duration-300" to="/contact">
          <div className="bg-white rounded-full px-8 py-3 text-center h-40 flex flex-col items-center justify-center">
            <h4 className="text-primary-gold font-semibold mb-2 text-xl">
              一緒に働けるスタッフを募集しております
            </h4>
            <span className="text-sm lg:text-base">
              医師・看護師・受付を随時募集中
              <br />
              こちらの応募フォームよりお気軽にご応募ください。
            </span>
          </div>
        </Link>
      </div> */}
    </section>
  )
}
