import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Massagepeel = ({ showMassagepeel, setShowMassagepeel }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowMassagepeel(false)
    }
  }
  return (
    <>
      {showMassagepeel ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowMassagepeel(!showMassagepeel)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/massagepeel-modal.jpg"
                alt="マッサージピール　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  マッサージピールとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  マッサージピールとダーマペンを組み合わせて肌を再生する力を高めていきます。施術時間は30分程度で、施術翌日からメイクをすることも可能です。
                  <br />
                  ピーリング剤だけでも、まるでレーザー施術を受けたかのようにお肌が綺麗になる施術が、イタリアから上陸しました。
                  <br />
                  マッサージピール（PRX-T33）は、医療機関で受けることのできるマッサージで浸透させるピーリングです。直後から肌質の改善やハリ感の向上、高い保湿力を感じることができます。
                  <br />
                  また併せてコウジ酸も5％配合されているため施術を重ねる毎に美白効果を発揮し、顔や体の色素沈着にも効果があります。
                  <br />
                  光治療器などと組み合わせることで、機器の効果もより高めることが可能です。
                  お肌を優しくマッサージしながら薬剤を塗布していき、拭き取った直後から皮膚の滑らかさやハリ感・弾力を感じられる
                  <span className="text-red-500 font-bold">
                    即効性に加え、ダウンタイムの心配はほとんどないピーリングの概念を覆す画期的な新治療です。
                  </span>
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  マッサージピールはこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・しわ・たるみを改善したい方
                  <br />
                  ・1回の治療でもハリ感を実感したい方
                  <br />
                  ・色素沈着などの黒ずみ改善
                  <br />
                  ・ニキビ跡や毛穴、傷跡を改善したい方
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  マッサージピールは皮膚のより深層にある真皮層に働きかける、「PRX-T33」という薬剤を使用します。
                  <br />
                  主成分はTCA（トリクロロ酢酸）と過酸化水素です。TCA（トリクロロ酢酸）は真皮層にまで浸透する比較的ダメージの強い（赤み、皮ムケ、色素沈着、瘢痕など）ピーリングとして知られているため、お肌全体に使用することは難しいとされていました。
                  <br />
                  マッサージピールは高濃度TCA（トリクロロ酢酸）でありながら、低濃度の過酸化水素を特別な組成で混合することによりお肌の炎症を最小限に抑え、なおかつ従来のコラーゲンを作り出す能力はそのまま保つことを可能にした、画期的な治療となっています。
                  <br />
                  皮膚の深層にまで浸透するため、真皮層の繊維芽細胞を活性化しコラーゲン生成を促します。
                  <br />
                  従来のピーリングでは改善の難しかったしわやたるみに効果を発揮し、ニキビ跡や毛穴、肉割れ、妊娠線などの改善にも有効です。
                  (別名コラーゲンピールとも呼ばれます。)
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・肌の状態によっては赤み、皮剥けが数日続く場合があります。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・当日からお化粧は可能ですが、赤みやヒリヒリ感がある場合は控えていただくか、なるべくお肌に優しいものをお使いください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
