import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Acneneedle = ({ showAcneneedle, setShowAcneneedle }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowAcneneedle(false)
    }
  }
  return (
    <>
      {showAcneneedle ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowAcneneedle(!showAcneneedle)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/acneneedle-modal.jpeg"
                alt="RFアクネ針治療　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  RFアクネ針治療とは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  RFアクネ針治療は皮脂腺を破壊することにより、ニキビ治療が可能になりました。
                  <br />
                  ニキビは皮脂腺から生じる毛包炎ですが、皮脂腺が消滅すればニキビの発症箇所がなくなります。
                  <br />
                  RFアクネ針治療による治療は、表皮に熱損傷を起こすことなく皮脂腺だけにエネルギーが伝わるように設計された効果的なニキビ治療です。
                  <br />
                  ニキビに直接針を刺し高周波を流し、皮脂腺を直接破壊することで、ニキビのできにくいお肌へと治療していきます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  RFアクネ針治療はこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・ニキビを治してもすぐに繰り返してしまう
                  <br />
                  ・ニキビ跡が気になる
                  <br />
                  ・ニキビで顔の赤みが気になる
                  <br />
                  ・重症化しすぎて治る気配がない
                  <br />
                  ・保険診療で治らなかった
                  <br />
                  ・スキンケアの方法が分からない
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  皮脂表面にダメージを与えず、ピンポイントで皮脂腺を破壊することが可能になりました。
                  <br />
                  これによって、ニキビが再発することは、ほぼなくなります。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <div className="flex items-center justify-center my-6 lg:w-5/6 mx-auto">
                  <StaticImage
                    src="../../content/assets/acneneedle-image.jpeg"
                    alt="RFアクネ針治療　機械　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <p className="text-sm leading-relaxed md:text-base">
                  マイクロニードルと呼ばれる極細絶縁針を真皮層まで通し、RF（高周波）を直接照射する治療です。
                  <br />
                  マイクロニードルからの正確に均一化されたRFエネルギーは、真皮層のコラーゲンとエラスチンを再生します。
                  <br />
                  表皮だけでなく皮膚深層部へ確実にRFを行き届かせることで、ニキビ・ニキビ跡等の症状を改善することができます。
                  <br />
                  RFアクネ針治療はニキビができていない部位も照射が可能です。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・施術後、赤みや腫れなど1～2週間程度で徐々に治まります。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・治まらないなど異常を感じましたら早めに受診してください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・当日はメイク・日焼止め・洗顔・入浴・飲酒・激しい運動等をお控えください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
