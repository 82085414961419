import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Velvetskin = ({ showVelvetskin, setShowVelvetskin }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowVelvetskin(false);
    }
  };
  return (
    <>
      {showVelvetskin ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowVelvetskin(!showVelvetskin)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/velvetskin-modal.jpg"
                alt="ヴェルヴェットスキン　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ヴェルヴェットスキンとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ヴェルヴェットスキンとは、
                  <span className="text-red-500 font-bold">
                    ニキビ・ニキビ跡の治療として人気のあるダーマペン、お悩みに合わせてお好みの薬剤を選んで頂くコンビネーション治療
                  </span>
                  のことをいいます。
                  <br />
                  恵比寿美容皮膚科のヴェルヴェットスキンは、マッサージピールという薬剤を使用します。
                  <br />
                  マッサージピーとは特殊な薬剤を用いたケミカルピーリングのことで、ダーマペンと合わせて治療をすることで
                  <span className="text-red-500 font-bold">約2倍</span>
                  の効果が得られると考えられています。
                  <br />
                  このダーマペンとは、微細な針を使用して行う美肌治療のことで、細い針を肌に指し、
                  <span className="text-red-500 font-bold">
                    あえて皮膚を傷つけ、肌本来の自然治癒力によって肌の再生を促進していく治療
                  </span>
                  です。
                  <br />
                  マッサージピールとダーマペンを組み合わせて肌を再生する力を高めていきます。施術時間は30分程度で、施術翌日からメイクをすることも可能です。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  ヴェルヴェットスキンははこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・ニキビ跡を改善したい方
                  <br />
                  ・毛穴の開きが気になる方
                  <br />
                  ・シワ・小ジワが気になるという方
                  <br />
                  ・たるみが気になりハリのある美肌を手に入れたい方
                  <br />
                  ・お肌のざらつきが気になる方
                  <br />
                  ・肌質改善をしたい方
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  ヴェルヴェットスキンはニキビ跡やくすみ、シワ、エイジングケアなど幅広い効果が期待できます。ヴェルヴェットスキンをすることによって肌のハリや弾力に欠かせないコラーゲンの生成が活性化されるため、コラーゲン不足が原因で起きる肌のトラブルの改善効果が期待でき、リフトアップ効果も期待できます。
                  <br />
                  さらに、ダーマペンの施術後に美容成分を含む薬剤を肌に塗布して、美容成分を直接肌の内側に送り込むことができます。
                  <br />
                  こうすることで美容成分が肌に浸透しやすくなるため、さらなる美肌効果が期待できます。
                  <br />
                  1回の治療でもかなり高い効果を実感していただけますが、1ヶ月ごとに治療を続けていただくことでさらに効果を実感していただけます。ニキビ痕、毛穴、肌質改善を目的とされる方は1ヶ月毎に4〜6回と治療を続けていただくことでさらに高い効果を期待できます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・非常に強い治療になりますので、お化粧は治療後24時間避けていただきます。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・ダウンタイムは赤みなどが多少残りますので、大事なご予定がある場合は医師にご相談ください。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・施術後12時間はお肌に何も塗れない為、日傘や帽子等ご持参ください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
