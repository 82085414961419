import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Photorf = ({ showPhotorf, setShowPhotorf }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowPhotorf(false)
    }
  }
  return (
    <>
      {showPhotorf ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowPhotorf(!showPhotorf)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/photorf-modal.jpeg"
                alt="フォトRF　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  フォトRFとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  <span className="leading-extra-loose">
                    ・しみ、くすみ、赤ら顔に効く！
                    <br />
                    ・痛みが少なくダウンタイムもほとんどなし！
                    <br />
                    ・安心のアメリカFDA承認レーザー！
                    <br />
                  </span>
                  470～980nmの広域な波長帯を照射するため赤色と茶色に対する反応性が高く、薄いしみにも効果を発揮します。
                  <br />
                  また、パルス幅が短いため破壊力にもすぐれています。
                  <br />
                  これまでの光による施術効果に高周波が加わったことにより、美肌・アンチエイジング効果をプラスした治療です。
                  <br />
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  フォトRFはこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・シミ・そばかす・くすみが気になる方
                  <br />
                  ・こじわが気になる方
                  <br />
                  ・赤ら顔でお悩みの方
                  <br />
                  ・ニキビ跡が気になる方
                  <br />
                  ・化粧ノリをよくしたい方
                  <br />
                  ・お肌にハリ感が欲しい方
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  光エネルギーと高周波エネルギーというダブルアクションによって、お肌のさまざまな悩みを改善する相乗効果が高まります。
                  <br />
                  シミだけでなく赤みやくすみまで改善し、なかでもそばかす治療により効果を上げています。
                  <br />
                  また光の作用で真皮層にあるコラーゲンの線維芽細胞を活性化するため、肌のハリの改善や開いた毛穴の状態も改善するなど、さまざまな側面からお肌のリニューアル効果が期待できます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <div className="flex items-center justify-center my-6">
                  <StaticImage
                    src="../../content/assets/photorf-image.jpg"
                    alt="フォトRF　機械　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <p className="text-sm leading-relaxed md:text-base">
                  一般的に東洋人の表皮にはメラニンが多く存在するため、レーザーやパルスライトで治療を行うとき、効果をあげようとして照射出力を上げていくと、強い痛みを伴い、表皮にダメージ（火傷）を与えてしまうこともあります。
                  <br />
                  フォトRFは光エネルギーを最小限に抑え、その補填としてRF（高周波）エネルギー使用します。
                  <br />
                  RFエネルギーは、光とは異なる選択性をもち、メラニンや血管の分布と無関係に働きます。従って、表皮のメラニン料に影響されずに、効果的に真皮層へ熱エネルギーを与えることができます。
                  <br />
                  これにより従来の光エネルギーによる痛みや火傷のリスクが抑えられ、肌や毛の色に影響されない治療が可能になると考えられています。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・すぐにメイクも可能で、周囲に治療を受けたことを気付かれることも通常はありません。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・シミに対してフォトフェイシャル治療を行った場合、一時的にシミは濃く浮き出たような状態になります。その後自然に脱落することでシミの元となっているメラニンが排出されることでフォトフェイシャルの美肌効果が得られます。
                  </span>
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  症例紹介
                </h6>
                <div className="lg:flex flex-wrap justify-center gap-y-4 lg:w-full mx-auto md:w-4/5">
                  <StaticImage
                    className="mt-6"
                    src="../../content/assets/photorf-beforeafter01.jpeg"
                    alt="フォトRF　ビフォーアフター画像 01"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
