import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const About = () => {
  return (
    <section className="py-8 bg-white">
      <div className="relative hidden mb-12 mx-auto px-8 max-w-6xl h-112 lg:block">
        <StaticImage
          className="w-full h-full border-4 border-primary-pink rounded-xl"
          src="../../content/assets/about-banner.jpeg"
          alt="クリニック詳細　画像"
          placeholder="blurred"
          quality="100"
        />
        <div className="absolute left-16 top-1/2 p-8 bg-pink-500 bg-opacity-50 rounded-2xl transform -translate-y-1/2">
          <h1 className="text-white text-2xl tracking-widest leading-extra-loose">
            より美しく、より若々しく
            <br />
            高いクオリティを求めるあなたへ
            <br />
            恵比寿から徒歩5分の非日常空間へ
            <br />
            ぜひお越しください。
          </h1>
        </div>
      </div>
      <div className="mb-8">
        <h3 className="mb-1 text-center text-primary-gold text-2xl font-bold tracking-widest md:mb-2 md:text-3xl">
          ABOUT US
        </h3>
        <h1 className="block text-center text-sm tracking-widest">
          恵比寿美容皮膚科について
        </h1>
      </div>
      <div className="relative flex items-center mb-8 mx-auto max-w-6xl md:mb-16 lg:mb-24">
        <div className="absolute left-8 top-8 hidden w-128 h-full bg-primary-pink rounded-2xl lg:block xl:w-140">
          <div className="xl:tranlate-y-72 w-fit-content text-secondary-gray text-5xl tracking-widest transform -translate-x-40 translate-y-48 rotate-90">
            INFORMATION
          </div>
        </div>
        <div className="z-10 flex flex-col gap-y-6 mt-16 px-4 w-full md:px-16 lg:ml-auto lg:px-8 lg:w-2/3">
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">名称</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              恵比寿美容皮膚科
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">開設</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              2021年3月
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
          {/* <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">院長</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              丸本 葵
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div> */}
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">診療内容</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              美容皮膚科
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">所在地</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              〒150-0013
              <br />
              東京都渋谷区恵比寿1-26-17 <br />
              阿部ビル3F
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">TEL</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              03-6450-4531
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
          <div className="flex flex-wrap gap-x-8 items-center lg:gap-x-28">
            <div className="py-1.5 w-24 text-center bg-secondary-gold rounded-full md:w-32">
              <span className="text-white font-bold lg:text-lg">定休日</span>
            </div>
            <span className="text-primary-gray tracking-wider lg:text-lg">
              不定休
            </span>
            <div className="mt-6 w-full h-px bg-gray-600" />
          </div>
        </div>
      </div>
      {/* <div className="mb-8">
        <h3 className="mb-1 text-center text-primary-gold text-2xl font-bold tracking-widest md:mb-2 md:text-3xl">
          GREETING
        </h3>
        <span className="block text-center text-sm tracking-widest">
          医師紹介
        </span>
      </div> */}
      <div className="mx-auto px-6 max-w-6xl">
        {/* <span className="text-primary-gold text-xl tracking-widest">
          AKIRA FURUTA
        </span>
        <div className="flex gap-x-8 items-center mt-2">
          <span className="text-2xl">院　長</span>
          <span className="text-3xl tracking-widest">古田 晃</span>
        </div>
        <div className="flex flex-col gap-8 items-start my-8 lg:flex-row">
          <p className="text-primary-gray leading-loose">
            コロナ禍での変化をきっかけに、自分なりに悩みと向き合いながら美容を楽しみたいと言う方が男性、女性問わず急激に増えています。
            <br />
            自粛生活が2年目に突入しアフターコロナに向けてアクションを起こし始めている方々も少なくないようです。こうした大きな社会的変化の中で、多くの方々の美容への意識変化が実際に散見され、美容は「がんばる」ためではなく癒しや幸せを感じるための大切なツールの1つとして「
            <span className="font-bold">自分軸</span>」「
            <span className="font-bold">癒し</span>」「
            <span className="font-bold">しなやか</span>」「
            <span className="font-bold">ご自愛</span>」「
            <span className="font-bold">アンチエイジング</span>
            」のような自分のための美容がより重要視されてきているようです。
            <br />
            これはかつて美容が限られた1部の女性の特権であったような時代がいつの間にか終わり、今では学生から高齢者までより幅広い層の方々に改めて意識され直している大切なそしてポジティブな変化の一環だと私は感じます。
            <br />
            恵比寿美容皮膚科ではそのような皆さまのニーズに少しでもお答えできればと、スタッフ一同、日々の診療に真摯に取り組んで参ります。どうぞよろしくお願いいたします。
          </p>
          <StaticImage
            className="w-full h-full"
            src="../../content/assets/dr-image.jpg"
            alt="院長　画像"
            placeholder="blurred"
            quality="100"
          />
        </div> */}

        {/* <div className="w-fit-content">
          <span className="text-2xl tracking-widest">経歴</span>
          <div className="mt-2 w-full h-px bg-primary-gold" />
        </div>
        <p className="my-8 text-primary-gray leading-loose">
          2019年　東京医科大学医学部医学科　卒業
          <br />
          同年　東京女子医科大学病院　初期臨床研修
          <br />
          2021年　日本大学医学部附属板橋病院　勤務
          <br />
          同年　都内大手美容皮膚科　勤務
          <br />
          2022年　当院入職
        </p> */}

        {/* <div className="w-fit-content">
          <span className="text-2xl tracking-widest">学会、その他</span>
          <div className="mt-2 w-full h-px bg-primary-gold" />
        </div>
        <p className="my-8 text-primary-gray leading-loose">
          ・フランス大使館指定医
          <br />
          ・医学博士
          <br />
          ・日本美容外科学会
          <br />
          ・アラガン•ジュビダームビスタセミナー修了
          <br />
          ・アラガン•ボトックスビスタセミナー修了
          <br />
          ・日本循環器学会
          <br />
          ・日本内科学会
          <br />
        </p> */}
      </div>
    </section>
  );
};
