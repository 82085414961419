import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Plasma = ({ showPlasma, setShowPlasma }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowPlasma(false);
    }
  };
  return (
    <>
      {showPlasma ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowPlasma(!showPlasma)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/plasma-modal.jpeg"
                alt="プラズマシャワー　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  プラズマシャワーとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  プラズマは固体、液体、気体のいずれとも異なる特有の性質を持つため、物質の第4の状態ともいわれています。
                  <br />
                  このプラズマの力を応用したものが「プラズマシャワー」です。
                  <br />
                  プラズマは皮膚の表面から表皮レベルまで作用します。
                  <br />
                  レーザーと違い、プラズマは皮膚、血液、表皮組織には衝撃を与えず、皮膚疾患の治療、滅菌、吸収性、皮膚の再生、抗色素沈着、皮膚の弾力性の向上に、ずばぬけた効果があります。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  プラズマシャワーはこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・ニキビ、肌荒れを改善したい
                  <br />
                  ・美白・透明感のある肌になりたい
                  <br />
                  ・赤みや色ムラを改善したい
                  <br />
                  ・乾燥が気になる
                  <br />
                  ・肌のハリ・弾力がなくなってきた
                  <br />
                  ・痛み、ダウンタイムのない施術を受けたい
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  プラズマは電気エネルギーを持つ電離した気体であり、皮膚の表面から表皮レベルまで作用します。
                  <br />
                  電気エネルギーが外側から内側に流れると、その流れとともに強さが変化し皮膚の異なる部分に別々の効果をもたらします。
                  <br />
                  プラズマを肌に当てると様々な効果が得られることが分かっています。
                  <br />
                  プラズマシャワーは、目元のリフトアップやしわの改善、傷跡の治療、滅菌作用によるニキビ改善、しみへの効果などたくさんの美肌効果が得られます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  プラズマエネルギーを皮膚にシャワーのように照射することで、皮膚、表皮組織、血液を傷つけることなく、細胞結合分子を一次的に切断することで吸収力を高め、飛躍的に薬剤の浸透率を高めます。（TDDS（経皮ドラッグデリバリーシステム））
                  <br />
                  また、プラズマ技術の進歩で、ニキビなどの肌トラブルを引き起こす細菌を死滅させ、古い角質や老廃物を吸着、繊維芽細胞を刺激しコラーゲン生成を促進することが可能となり、あらゆるレーザーなどの機器と注入施術の効果とその持続力を高める効果も期待できます。
                  <br />
                  <br />
                  プラズマとは気体が高温度および高電力でイオン化された固体→液体→気体に続く物質の第4の状態です。
                  <br />
                  プラズマエネルギーが皮膚表面に届くと、細菌の接着分子を一時的に切断されることで皮膚に対する薬剤の吸収率を飛躍的に高められます。これがTDDS(経皮ドラッグデリバリーシステム)です。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・治療後は、治療部位の皮膚が少し赤身を帯びる程度です。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・ダウンタイムはほとんどありません。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・30分後からメイク可能です。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
