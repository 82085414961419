import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Microneedle = ({ showMicroneedle, setShowMicroneedle }) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowMicroneedle(false);
    }
  };
  return (
    <>
      {showMicroneedle ? (
        <div
          className="fixed inset-0 w-screen h-screen overflow-y-scroll bg-secondary-gray bg-opacity-60 p-2 z-50"
          ref={modalRef}
          onClick={closeModal}
        >
          <div className="relative max-w-7xl mx-auto bg-white py-6 overflow-y-scroll">
            <button
              ref={modalRef}
              onClick={() => setShowMicroneedle(!showMicroneedle)}
              className="text-xl fixed top-4 right-4 md:right-8 xl:bg-white xl:rounded-full xl:w-12 xl:h-12"
            >
              ×
            </button>
            <div className="mt-10 flex max-w-xl mx-auto lg:max-w-3xl">
              <StaticImage
                className="w-full"
                src="../../content/assets/microneedle-modal.jpeg"
                alt="マイクロニードル高周波RF　画像"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="max-w-2xl lg:max-w-4xl mx-auto">
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  マイクロニードル高周波RFとは
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  超極細のマイクロニードルを用いた低侵襲フラクショナルRFです。
                  <br />
                  マイクロニードルをお肌に刺入することで小さな傷ができ、傷を自ら治そうとする創傷治癒の力によってコラーゲンの生成を促進させます。
                  <br />
                  また、マイクロニードルの先端からRF（高周波）エネルギーを出力し、真皮層に熱刺激を与えることで肌の深層でタンパク質変性を起こさせ、コラーゲンの生成などによって、素肌本来の美しい肌づくりの効果をより高めることができます。
                  <br />
                  針を用いることで表皮のダメージが抑えられ、ダウンタイム少なくニキビ跡や毛穴、小じわ、たるみなどのあらゆる肌悩みを改善できます。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  マイクロニードル高周波RFはこのような方におすすめです
                </h6>
                <p className="text-sm leading-extra-loose md:text-base">
                  ・ニキビ・ニキビ跡、クレーターをきれいにしたい
                  <br />
                  ・毛穴の開き、たるみ毛穴を改善したい
                  <br />
                  ・目元や肌全体にハリがない
                  <br />
                  ・くすみのない美肌になりたい
                  <br />
                  ・しわ、小じわを改善したい
                  <br />
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  効果
                </h6>
                <p className="text-sm leading-relaxed md:text-base">
                  針から真皮層にしっかりとした熱刺激を与えることができるので、ニキビ跡で陥没した凸凹肌や、開いた毛穴、小じわやたるみを改善しキメが整った滑らかな肌に。
                  <br />
                  みずからの肌再生機能を利用した肌の若返り治療です。
                  <br />
                  同じマシン「Gentlo」で行う、プラズマシャワーの薬剤導入との併用もおすすめです。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  機械について
                </h6>
                <div className="flex items-center justify-center my-6">
                  <StaticImage
                    src="../../content/assets/microneedle-image.jpeg"
                    alt="マイクロニードル　機械　画像"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
                <p className="text-sm leading-relaxed md:text-base">
                  RFフラクショナルニードルには、針先0.3mm部分のみ正確に高周波RFを届けるInsulatedタイプと、針全体に高周波RFを届けるNon-Insulatedタイプの2種類の異なるタイプがあります。
                  <br />
                  これを使い分けることで高周波の照射範囲が選択できるので、一人一人の肌悩みに合わせた治療が可能となっています。
                </p>
              </div>
              <div className="px-4 py-6 tracking-wider">
                <h6 className="text-primary-gold text-xl mb-4 md:text-2xl">
                  リスク・副作用・注意点・ダウンタイム
                </h6>
                <p className="text-sm md:text-base">
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・ダウンタイムの無い施術から、長くて施術後1週間ほど赤みが残る施術まで治療によって異なります。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・メイクは翌日から可能です。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block mb-3">
                    ・当日からシャワーは可能ですが、入浴・サウナ・飲酒・運動はお控えください。体が温まることで炎症が起きる場合がございます。
                  </span>
                  <span className="w-full text-sm leading-relaxed md:text-base block">
                    ・施術後12時間はお肌に何も塗れない為、日傘や帽子等ご持参ください。
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
