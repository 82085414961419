import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { MobileMenu } from "./MobileMenu";

export const Header = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      lineIcon: file(relativePath: { eq: "line-icon.svg" }) {
        publicURL
      }
      instagramIcon: file(relativePath: { eq: "instagram-icon.svg" }) {
        publicURL
      }
      twitterIcon: file(relativePath: { eq: "twitter-icon.svg" }) {
        publicURL
      }
      facebookIcon: file(relativePath: { eq: "facebook-icon.svg" }) {
        publicURL
      }
      flagFr: file(relativePath: { eq: "flag-fr.svg" }) {
        publicURL
      }
      flagEn: file(relativePath: { eq: "flag-en.svg" }) {
        publicURL
      }
    }
  `);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  });
  return (
    <header
      className={`w-screen relative md:flex flex-col items-center justify-center`}
    >
      <div className="p-4">
        <a
          href="tel:03-6450-4531"
          className="hidden lg:block absolute left-8 top-8"
        >
          <span className="text-white font-bold tracking-wider">
            tel. 03-6450-4531
          </span>
          <div className="mt-1 w-full h-px bg-white" />
        </a>
        <Link
          className={`${
            open ? "fixed left-0 pl-4 top-0 pt-4 bg-primary-pink w-full" : ""
          } flex items-center z-50 w-fit-content lg:mx-auto`}
          to="/"
        >
          <img
            className="w-16 lg:w-20"
            src={data.logo.publicURL}
            alt="恵比寿美容皮膚科　ロゴ"
          />
          <div className="flex flex-col items-center justify-center ml-2 text-white tracking-widest">
            <span className="font-semibold text-2xl lg:text-3xl">
              恵比寿美容皮膚科
            </span>
            <span className="font-semibold text-xs lg:text-sm">
              EBISU BEAUTY CLINIC
            </span>
          </div>
        </Link>
        <button
          onClick={() => setOpen(!open)}
          className={`${
            open ? "open fixed" : "absolute"
          } z-50 lg:hidden right-4 top-8`}
          aria-label="メニューボタン"
        >
          <div
            className={`${
              open ? "transform rotate-45 translate-y-2" : ""
            } w-6 h-0.5 bg-white rounded-full duration-300`}
          />
          <div
            className={`${
              open ? "hidden" : ""
            } mt-1.5 w-6 h-0.5 bg-white rounded-full transform duration-300`}
          />
          <div
            className={`${
              open ? "transform -rotate-45" : ""
            } mt-1.5 w-6 h-0.5 bg-white rounded-full duration-300`}
          />
        </button>
        <MobileMenu open={open} setOpen={setOpen} />
        <nav className="hidden lg:block w-full text-white mt-6">
          <ul className="flex items-center justify-center gap-x-4 xl:gap-x-8">
            <li className="font-bold">
              <Link
                to="/"
                className="text-white font-semibold w-fit-content group"
              >
                HOME
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
            <li className="font-bold">
              <Link
                to="/about"
                className="text-white font-semibold w-fit-content group"
              >
                クリニック紹介
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
            <li className="font-bold">
              <Link
                to="/treatment"
                className="text-white font-semibold w-fit-content group"
              >
                施術内容
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
            <li className="font-bold">
              <Link
                to="/treatment#price"
                className="text-white font-semibold w-fit-content group"
              >
                料金表
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
            <li className="font-bold">
              <Link
                to="/contact"
                className="text-white font-semibold w-fit-content group"
              >
                お問合わせ
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
            <li className="font-bold">
              <Link
                to="/news"
                className="text-white font-semibold w-fit-content group"
              >
                お知らせ・ブログ
                <div className="w-0 mx-auto h-px bg-white group-hover:w-full transform duration-300 mt-2" />
              </Link>
            </li>
          </ul>
        </nav>
        <div className="hidden absolute top-24 right-8 w-fit-content lg:flex items-center justify-center gap-x-2">
          <a href="https://lin.ee/Vw3HGc9" target="_blank" rel="noreferrer">
            <img
              className="w-12 h-12"
              src={data.lineIcon.publicURL}
              alt="LINE ロゴ"
            />
          </a>
          <a
            href="https://twitter.com/ebisubeauty2021"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-12 h-12"
              src={data.twitterIcon.publicURL}
              alt="twitter ロゴ"
            />
          </a>
          <a
            className="bg-white rounded-full"
            href="https://www.facebook.com/profile.php?id=100077261290093"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-12 h-12"
              src={data.facebookIcon.publicURL}
              alt="facebook ロゴ"
            />
          </a>
          <a
            href="https://www.instagram.com/ebisubeauty2021/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-12 h-12"
              src={data.instagramIcon.publicURL}
              alt="instagram ロゴ"
            />
          </a>
        </div>
      </div>
    </header>
  );
};
