import React from "react";
import { NewsHome, BlogHome } from "./index";

export const NewsBlog = () => {
  return (
    <section className="bg-white p-4 md:py-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h4 className="text-center text-2xl font-bold text-primary-gold tracking-widest mb-1 md:text-3xl md:mb-2">
            NEWS・BLOG
          </h4>
          <span className="text-center block text-sm tracking-widest">
            お知らせ・スタッフブログ
          </span>
        </div>
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8 xl:gap-16">
          <NewsHome />
          <BlogHome />
        </div>
      </div>
    </section>
  );
};
